.gradientBackground {
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

@media (max-width: 980px) {
  .tableDashboard {
    font-size: small;
  }
  button {
    font-size: 0.8rem;
  }
}
