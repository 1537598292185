.logoJocemara {
  width: 300px;
}

.customNavbar a {
  color: black;
  font-weight: 500;
  transition: all 0.8s ease-in-out;
}

.customNavbar a.active span {
  border-bottom: 2px solid #ed8547;
}
.customNavbar a:hover {
  color: #ed8547;
}

@media (max-width: 980px) {
  .logoJocemara {
    width: 200px;
  }
}
