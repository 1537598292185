@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wdth,wght@0,6..12,100.7,200..1000;1,6..12,100.7,200..1000&display=swap");

body {
  margin: auto;
  font-family: "Nunito Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.custom-primary-btn {
  position: relative;
  padding: 0.4rem 3.25rem 0.4rem 1.5rem;
  background-color: #ed8547;
  color: #fff;
  border: 1px solid #be4803;
  font-size: 1.1rem;
  font-weight: 600;
  transition: all 0.3s ease;
  cursor: pointer;
}

.custom-primary-btn:hover {
  background-color: #a62a59;

  border: 1px solid #be4803;
}

.custom-primary-btn::after {
  content: url("./img/icon-chevron-right.svg");
  position: absolute;
  display: block;
  width: 30px;
  right: 10px;
  top: 50%;
  padding-top: 6px;
  transform: translateY(-50%);
  transition: transform 0.3s ease;
}

.custom-primary-btn:hover::after {
  transform: translate(10px, -50%);
}

.custom-profile-btn {
  position: relative;
  padding: 0.4rem 1.5rem 0.4rem 1.5rem;
  background-color: #ed8547;
  color: #fff;
  border: 1px solid #be4803;
  font-size: 1.1rem;
  font-weight: 600;
  text-decoration: none;
}

.custom-profile-btn:hover {
  background-color: #a62a59;
}

.custom-secondary-btn {
  background-color: #6c757d;
  color: #fff;
}

.custom-outline-btn {
  padding: 0.4rem 1.5rem;
  color: #fff;
  border: 1px solid #ed8547;
  font-size: 0.75rem;
  font-weight: 400;
  outline: #ed8547;
  color: #be4803;
}

.text-orange {
  color: #be4803;
}

.custom-primary-outline-btn {
  position: relative;
  padding: 0.4rem 3.25rem 0.4rem 1.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  transition: all 0.3s ease;
  cursor: pointer;
  background-color: #fff;
  outline: 2px solid #a62a59;
  color: #a62a59;
}
.custom-primary-outline-btn::after {
  content: url("./img/icon-chevron-right-red.svg");
  position: absolute;
  display: block;
  width: 30px;
  right: 10px;
  top: 50%;
  padding-top: 6px;
  transform: translateY(-50%);
  transition: transform 0.3s ease;
}

.custom-primary-outline-btn:hover::after {
  transform: translate(10px, -50%);
}

.custom-primary-outline-btn:hover {
  background-color: #a62a59;
  color: #fff;
}

.bckImg {
  background-image: url("./img/background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.bckImgTestimonials {
  background-image: url("./img/background_img.jpg");
  background-repeat: repeat-y;
  background-size: contain;
}
.bckBeige {
  background-color: #f5e2d4;
}
.bckBrown {
  background-color: #e1a88d;
}

.text-red {
  color: #a62a59;
}
.whatsIcon img {
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 70px;
  box-shadow: 3px 3px 3px #4b4b4b;
  border-radius: 50%;
  object-fit: cover;
  transition: all 0.3s ease-in-out; /* Suaviza a transição */
}

.whatsIcon:hover img {
  content: url("../src/img/whats_icon_hover.svg");
}

.editButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media (max-width: 980px) {
  table {
    font-size: small;
  }
  .editButtons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
