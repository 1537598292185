.customContainer {
}

@media (max-width: 980px) {
  .customContainer {
    margin-top: 10px;
  }

  .customButtonLeft {
    left: -60px !important;
    height: 180px;
    opacity: 1;
  }

  .customButtonRight {
    right: -60px !important;
    height: 180px;
    opacity: 1;
  }

  .customIconLeft {
    background-image: url("./img/arrow_right.svg");
    height: 2rem !important;
    transform: scaleX(-1);
    opacity: 1;
  }

  .customIconRight {
    background-image: url("./img/arrow_right.svg");
    height: 2rem !important;
  }
  .testimonialContainer {
    height: 350px;
    background-color: #fff;
    width: 100%;
  }
}

.customButtonLeft {
  left: -170px;
  /* height: 180px; */
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: 1;
  transition: opacity 0.15s ease;
}

.customButtonRight {
  right: -170px;
  /* height: 180px; */
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: 1;
}

.customIconLeft {
  background-image: url("./img/arrow_right.svg") !important;
  height: 4rem;
  transform: scaleX(-1);
}

.customIconRight {
  background-image: url("./img/arrow_right.svg") !important;
  height: 4rem;
}

.customQuotes {
  height: 2rem;
}

.testimonialContainer {
  height: fit-content;
  background-color: #fff;
}

.clientName {
  color: #a62a59 !important;
  font-weight: 600;
}
