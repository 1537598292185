.serviceImg {
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  margin-left: -120px;
  top: 120px;
}

@media (max-width: 980px) {
  .serviceImg {
    position: relative;
    margin-left: 0px;
    top: 0px;
  }
}
