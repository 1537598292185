/* Footer.css */

.footerLogo {
  max-width: 250px;
}

.footerAddress {
  font-size: 0.9em;
  line-height: 1.4;
}

.footerCopyright {
  text-align: center;
  font-size: 0.8em;
  color: #47210c;
}

@media (max-width: 980px) {
  .footerCopyright {
    text-align: center;
    font-size: 0.65em;
    color: #47210c;
  }
}

.noPadding p {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}
