.cardBody {
  border-radius: 0;
}

.cardServices {
  object-fit: cover;
  height: 276px;
  width: 100%;
}

.card {
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.hrCustom {
  height: 1px;
  background-color: black;
  border: none;
  margin: auto;
  width: 30%;
}

.cardTitle {
}

.cardService {
  text-decoration: none !important;
  color: rgb(80, 17, 17);
  transition: all 0.3s ease;
}

.cardService:hover h3 {
  font-size: 2rem;
  transition: all 0.3s ease;
}

.cardService:hover button {
  background-color: #ed8547;
  color: #fff;
}

h4.cardTitle {
  font-size: 1rem;
}

@media (max-width: 980px) {
  .cardServices {
    height: 200px !important;
  }
  h4.cardTitle {
    font-size: 0.75rem;
  }
  h3 {
    font-size: 1.25rem !important;
    line-height: 1.25rem !important;
  }
}
