/* Home.module.css */
.homeContainer {
  background-image: url("./img/background.jpg");
  background-size: cover;
  text-align: left;
}

.wrappedContainer {
  margin-top: 80px;
  margin-bottom: 80px;
  background-color: #ffffff;
}

h1.homeH1 {
  font-size: 2rem;
}

.customLead {
  font-size: 1.25rem;
  line-height: 1.7rem;
}

img.highlightImage {
  position: absolute;
  width: 450px;
  height: 450px;
  border-radius: 50%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  top: 130px;
}

@media (max-width: 980px) {
  img.highlightImage {
    position: relative;
    top: 0px;
    border-radius: 50%;
    width: 350px;
    height: 350px;
  }

  .wrappedContainer {
    background-image: url("./img/joce_media_highlight.jpg");
    background-size: cover;
    margin-top: 0px;
    margin-bottom: 20px;
    padding-right: 0px;
    padding-left: 0px;
    background-position: right top;
    background-repeat: no-repeat;
    background-color: transparent;
    color: #fff;
  }
  h1.homeH1 {
    font-size: 1.5rem;
    width: 70%;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
  }
  .customLead {
    font-size: 1rem;
    line-height: 1.25rem;
    width: 70%;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
  }
  .homeContainer {
    background-image: none;
  }
}
