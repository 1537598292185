.profileImg {
  position: absolute;
  bottom: 0px;
  left: 100px;
}

@media (max-width: 980px) {
  .profileImg {
    position: relative;
    bottom: 0px;
    left: 0px;
    width: 250px;
  }
}
